@use "../Variables.scss";

#projects-container {
    background-color: Variables.$secondary-background-color;
    width: 100%;
    padding-top: Variables.$nav-height;
    padding-bottom: 40px;

    h1 {
        text-align: center;
        font-size: 3em;
        padding-bottom: 0;
        padding-top: 40px;
        margin-bottom: 0;
    }

    hr {
        border: 1px solid Variables.$font-color;
        width: 20%;
    }

    #projects {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        margin-left: 10%;
        margin-right: 10%;

        .project.visible {
            animation: fade-in-bottom 0.5s;
            animation-fill-mode: forwards;
        }

        .project {
            $padding: 20px;
            $margin: 10px;
            $cols: 3;
            
            width: calc(100% / $cols - ($padding * 2 + $margin * 2));
            min-width: 330px;
            text-overflow: hidden;
            margin: $margin;
            padding: $padding;
            background: Variables.$project-box-color;
            color: Variables.$project-box-font-color;
            border-radius: 10px;
            box-shadow: rgba(100, 100, 100, 0.2) 0px 5px 30px 0px;
            opacity: 0;

            .title {
                padding-top: 0;
                margin-top: 0;
            }

            .repository {
                color: Variables.$background-color;
                cursor: pointer;
                text-decoration: underline;
            }

            .skills {
                list-style-type: none;
                padding-inline-start: 0;

                .skill {
                    display: inline-block;
                    background: Variables.$project-box-skill-color;

                    padding: 10px;
                    margin: 10px;
                }
            }
        }

        .project.placeholder {
            opacity: 0;
        }
    }
}