$background-color: #48b5e0;
$secondary-background-color: #0b8abd;
$scrollbar-thumb-color: #249bca;
$font-color: #fff;
$link-hover-color: #eee;
$project-box-color: #f1f1f1;
$project-box-font-color: #000;
$project-box-skill-color: #ffffff;

$nav-height: 60px;

$project-col-count: 4;