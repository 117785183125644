@keyframes fade-in {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes partial-to-fade-in {
  from {
    opacity: 0.3;
  }
  to {
      opacity: 1;
  }
}

@keyframes fade-in-bottom {
  from {
      opacity: 0;
      transform: translateY(100%);
  }
  to { 
      opacity: 1;
  }
}