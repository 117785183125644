@use "../Variables.scss";

#nav {
    position: fixed;
    top: 0;
    background-color: Variables.$background-color;
    width: 100%;
    height: Variables.$nav-height;
    z-index: 100;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            float: right;
            display: inline;
            margin-right: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}