@use "../Variables.scss";
@use "../Animations.scss";

#experiences-container {
    background-color: Variables.$background-color;
    width: 100%;
    padding-top: Variables.$nav-height;
    padding-bottom: 40px;

    h1 {
        text-align: center;
        font-size: 3em;
        padding-bottom: 0;
        padding-top: 40px;
        margin-bottom: 0;
    }

    > hr {
        border: 1px solid Variables.$font-color;
        width: 20%;
    }

    #experiences {
        padding-top: 50px;

        .experience.visible {
            animation: partial-to-fade-in 1s;
            animation-fill-mode: forwards;
        }

        .experience {
            margin-left: auto;
            margin-right: auto;
            width: 70%;
            color: #eeeeee;
            display: flex;
            align-items: stretch;
            opacity: 0.3;

            .experience-company {
                margin: 0;
                padding: 0;
            }

            .experience-title, .experience-duration {
                font-size: 1.1em;
            }

            .experience-responsibilities {
                padding-inline-start: 1em;

                .experience-responsibility {
                    padding-bottom: 10px;
                }
            }

            .experience-info {
                width: 90%;
                padding-bottom: 100px;
            }

            .experience-timeline {
                width: 10%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .experience-timeline-icon {
                    float: right;
                    margin-right: 50px;
                    width: 50px;
                    height: 50px;
                    border-radius: 30px;
                    background-color: Variables.$font-color;
                    position: relative;
                    left: 24.5px;
                    color: Variables.$background-color;
                    text-align: center;
                    font-size: 35px;
                }
                
                .experience-timeline-line {
                    height: 100%;
                    width: 2px;
                    float: right;
                    margin-right: 50px;
                    background-color: Variables.$font-color;
                }

            }

            .experience-clear {
                clear: both;
            }
        }

    }
}