@use "./Variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@500;700&display=swap");

html, body, #root {
  height: 100%;
}

body {
  background: Variables.$background-color;
  font-family: 'Cabin', sans-serif;
}

a {
  text-decoration: none;
  color: Variables.$font-color;
}

a:hover {
  color: Variables.$link-hover-color;
}